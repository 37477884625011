<template>
	<div>
		<div class="introduce-module" id="ab1">
			<div class="module-title">
				<div class="title-name">公司简介</div>
				<div class="module-dis-content">
					<div>
						中译文娱是一家人工智能视觉传播科技企业，隶属于中国出版集团旗下中国对外翻译有限公司，专注于AI视觉信息分析、国际传播和新消费领域；自主研发了图像识别、视频语义分析、多模态内容理解和人机交互等核心技术引擎；构建起覆盖文化传媒、游戏娱乐、数字藏品、科技教育和跨境电商等行业应用生态链接产业集群，推动下一代AI视觉科技与行业融合发展，赋能产业数字化转型。目前在北京、青岛两地已开设公司。
					</div>
					<div>
						中译文娱致力于视觉场景的技术研发及问题解决。视觉场景不同于文本场景，视觉场景包含视频、图像、语音等多模态信息，具备信息格式丰富、信息背景多样，信息处理难度极大等特点。中译文娱自主研发了国内领先的以影像数据为核心的数据挖掘技术、基于多计算引擎的影像内容分析技术、智能影像生产技术和跨平台视听信息国际传播技术等多项核心技术，建立了从视觉素材汇聚、数据治理（内容理解、精细化提取、结构化存储）、内容生产、内容分发于一体的综合性人工智能处理平台，可覆盖视觉业务的全生命周期，全链路助力降本增效。
					</div>
				</div>
			</div>
		</div>
		<div class="introduce-module" id="ab2">
			<div class="module-title">
				<div class="title-name">发展历程</div>
			</div>
			<div class="company-dev">
				<img src="../../../assets/image/aboutus/8.svg" alt="" />
			</div>
		</div>
		<div class="company-deploy">
			<img src="@/assets/image/aboutus/gsjj-bottom.svg" alt="" />
		</div>
	</div>
</template>
<script>
import {
	contact
} from '../api';
import data from '@/utils/data';

import c1 from '@/assets/image/aboutus/2.png';
import c2 from '@/assets/image/aboutus/3.png';
import c3 from '@/assets/image/aboutus/4.png';
import c4 from '@/assets/image/aboutus/5.png';
import c5 from '@/assets/image/aboutus/6.png';
import c6 from '@/assets/image/aboutus/7.png';
export default {
	mixins: [data],
	data() {
		return {
			imgList: [c1, c2, c3, c4, c5, c6],
			tableInfo: {
				name: '',
				email: '',
				phone: '',
			},
			mapInfo: {
				qd: [{
					name: '联系人',
					value: '王锴',
				},
				{
					name: '电话',
					value: '18766257116',
				},
				{
					name: '邮箱',
					value: 'wangkai@gtcom.com.cn',
				},
				// {
				//   name: '网址',
				//   value: 'www.yeefuntv.com',
				// },
				{
					name: '地址',
					value: '山东省青岛市崂山区石岭路39号名汇国际2号楼2907',
				},
				],
				bj: [{
					name: '联系人',
					value: '王锴',
				},
				{
					name: '电话',
					value: '18766257116',
				},
				{
					name: '邮箱',
					value: 'wangkai@gtcom.com.cn',
				},
				// {
				//   name: '网址',
				//   value: 'www.yeefuntv.com',
				// },
				{
					name: '地址',
					value: '北京市石景山区五一剧场南路6号院1号楼1层',
				},
				],
			},
			// table
		};
	},
	methods: {
		go(type, key) {
			// type map 地图； company 公司详情
			if (type == 'map') {
				const locations = {
					qd: 'https://j.map.baidu.com/80/Qnpi',
					bj: 'https://j.map.baidu.com/70/YF6i',
				};
				key && window.open(locations[key]);
			}
			if (type == 'company') {
				this.$router.push({
					path: '/company'
				});
			}
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let params = {
						linkmanName: this.tableInfo.name,
						linkmanTel: this.tableInfo.phone,
						linkmanEmail: this.tableInfo.email,
					};
					contact(params).then(() => {
						this.$message.success('提交成功');
						this.$refs[formName].resetFields();
					});
				} else {
					return false;
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.about-us-page {
	.introduce-module {
		margin-top: 80px;
	}

	.module-title {
		text-align: center;
	}

	.title-name {
		height: 40px;
		font-size: 28px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		line-height: 40px;
	}

	.module-dis-content {
		margin-top: 30px;
		text-align: left;
		text-indent: 32px;
		font-weight: 400;
		font-size: 16px;
		line-height: 30px;
		color: #666666;
	}

	.title-en-name {
		height: 28px;
		font-size: 20px;
		font-weight: 400;
		color: #b8b8b8;
		line-height: 28px;
	}

	.company-dev {
		display: flex;
		justify-content: center;

		img {
			margin-top: 30px;
			width: 800px;
		}
	}

	.company-deploy {
		img {
			@extend .mb40;
			width: 100vw;
			margin-top: 80px;
			margin-left: calc((100vw - 1350px) / -2);
		}
	}

	.module-content {
		margin-top: 50px;
	}

	.company-desc {
		display: flex;
		justify-content: space-between;
		margin-bottom: 100px;
	}

	.left-info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 620px;
		height: 380px;
	}

	.left-text {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;

		line-height: 30px;
	}

	.left-button {
		cursor: pointer;
		width: 150px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		background: #af406c;
		color: #fff;
		border-radius: 4px;
	}

	.right-img {
		img {
			width: 520px;
			height: 380px;
		}
	}

	.company-map {
		margin-top: 100px;
		box-shadow: 2px 2px 14px 0px rgba(150, 150, 150, 0.34);
		border-radius: 8px;
		overflow: hidden;
	}

	.address-title {
		font-size: 14px;
		font-weight: 400;
		color: #222222;
		padding-left: 10px;
	}

	.map-img {
		width: 1200px;
		background: #e6ecf6;
		height: 300px;
		position: relative;
	}

	.left-mg {
		height: 300px;
	}

	.right-info {
		position: absolute;
		top: 0;
		right: 0;
		height: 300px;
		width: 410px;
		background-color: #e6ecf6;
		box-sizing: border-box;
		padding: 40px 50px;
	}

	.right-info-item {
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		display: flex;
	}

	.info-title {
		width: 60px;
	}

	.module-imgs {
		margin-top: 50px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.module-img-item {
		margin-bottom: 30px;
		width: 380px;
		height: 250px;
	}

	.join-us-table {
		margin-top: 50px;
		display: flex;
		justify-content: center;
	}

	.btn-center {
		display: flex;
		justify-content: center;
	}
}

.about-us-page {
	.el-form-item__content {
		width: 480px;
		margin-left: 0 !important;
	}

	.el-input__inner {
		background: #fafafa;
	}

	.btn-center .el-button {
		width: 180px;
		height: 50px;
		margin-top: 30px;
		background: #af406c;
		color: #fff;
	}

	.el-collapse-item__content {
		padding-bottom: 0px;
	}
}
</style>
